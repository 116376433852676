import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InboundActivityService } from '../inbound-activity/InboundActivityService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { InputText } from 'primereact/inputtext';
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { Message } from 'primereact/message';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { InputSwitch } from 'primereact/inputswitch';
import "./InboundActivityComponent.css";
import Spinner from '../Spinner'
import { Calendar } from 'primereact/calendar';
import { ProdItemSettingService } from '../prodItemSettings/ProdItemSettingSerivce';



export class InboundActivityComponent extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            hideSpinner: false,
            pageErrorMessage: null,
            pageSuccessMessage: null,
            InboundMetricList: [],
            inboundMetricTemp: [],
            InstanceList: [],
            NamespaceList: [],
            InterfaceList: '',
            showpagination: false,
            hasReadRights: true,
            actionCommentsText: '',
            isReviewed: sessionStorage.getItem("isReviewed") === 'true' ? true : false,
            inActiveInterface: sessionStorage.getItem("inActiveInterface") === 'true' ? true : false,
            actionCommentPanelVisible: false,
            selectedRows: [],
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'System': { value: null, matchMode: FilterMatchMode.IN },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'Namespace': { value: null, matchMode: FilterMatchMode.IN },
                'InterfaceName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            updateMultipleSelectedDialog: false,
            cacheInboundMetricRequest: {
                Id: 0,
                InactivityTimeout: 0,
                IsBackloadService: false,
                IsReviewed: false,
                Decommissioned: false,
                Comments: null,
                EditReason: null,
                Ids: []
            },
            selectedAutoRefresh: 0,
            intervalId: null,
            autoRefreshOptions: [
                { label: 'Auto-Refresh Off', value: 0 },
                { label: '5 minute', value: 300000 },
                { label: '10 minute', value: 600000 },
                { label: '15 minute', value: 900000 },
                { label: '30 minute', value: 1800000 }
            ],
            // HDO-6801 - Selected input params for search
            inboundSelectedInstance: JSON.parse(sessionStorage.getItem("inboundSelectedInstance")) ?? '',
            inboundSelectedNamespace: JSON.parse(sessionStorage.getItem("inboundSelectedNamespace")) ?? '',
            inboundSelectedInterface: sessionStorage.getItem("inboundSelectedInterface") ?? '',
            LastInboundSelectedDate: sessionStorage.getItem("lastInboundSelectedDate") ? new Date(sessionStorage.getItem("lastInboundSelectedDate")) : null,
            lastInboundSelectedCondition: sessionStorage.getItem("lastInboundSelectedCondition"),
            lastInboundSelectedConditionChecked: sessionStorage.getItem("lastInboundSelectedCondition") === "HIGH" ? true : false,
        };
        this.inboundActivityService = new InboundActivityService();
        this.prodItemSettingService = new ProdItemSettingService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getHIEInstances();
    }

    getHIEInstances() {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.prodItemSettingService.getHIEInstances("CACHE", this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.InstanceList.push({ label: item.ServerInstance, value: item.ServerInstance })));
                        this.getProductions(['ALL'])
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    // HDO-6801 - Added to load the namespace list
    getProductions(instance) {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        this.prodItemSettingService.getProductions(instance, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.NamespaceList.push({ label: item.Namespace, value: item.Namespace })));
                        this.loadInboundMetricData();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    // HDO-6801 - Added to load the intance list
    loadInboundMetricData = () => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null });
        let LastInboundSelectedDateEst = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(this.state.LastInboundSelectedDate)
        this.inboundActivityService.getInboundData(this.state.inboundSelectedInstance, this.state.inboundSelectedNamespace, this.state.inboundSelectedInterface,
            LastInboundSelectedDateEst, this.state.lastInboundSelectedCondition === null ? "HIGH" : this.state.lastInboundSelectedCondition, this.props.msalContext).then(
                (d) => {
                    if (d.status === 403) {
                        this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                        this.setState({ hasReadRights: false });
                    } else {
                        if (this.state.inActiveInterface) {
                            this.setState({ InboundMetricList: d.body.filter(p => p.CurrentFrequencyInSeconds > p.InactivityTimeout) })
                        }
                        else if (this.state.isReviewed) {
                            this.setState({ InboundMetricList: d.body.filter(p => p.IsReviewed === this.state.isReviewed) })
                        }
                        else
                            this.setState({ InboundMetricList: d.body })

                        this.setState({ inboundMetricTemp: d.body });

                        if (d.body.length > 15)
                            this.setState({ showpagination: true, hideSpinner: true })
                        else
                            this.setState({ hideSpinner: true })
                    }
                }
            ).catch(e => {
                this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!' })
                console.log(e)
            });
    }

    loadInboundMetricDataOnDemand = () => {
        this.setState({ hideSpinner: false })
        this.loadInboundMetricData()
    }

    onRowDoubleClick = (e) => {
        let redirectUrl = '/component/' + e.data.Id
        this.props.history.push(redirectUrl)
    }

    handleMultiDropDownInputChange = (event) => {
        sessionStorage.setItem([event.target.name], JSON.stringify(event.value));
        this.setState({ [event.target.name]: event.value, pageErrorMessage: null, pageSuccessMessage: null })
    }

    // HDO-6801 
    handleSearchInputChange = (event) => {
        sessionStorage.setItem([event.target.name], event.target.value);
        this.setState({ [event.target.name]: event.target.value, pageErrorMessage: null, pageSuccessMessage: null })
    }

    onLastInboundSelectedConditionChecked = (event) => {
        if (event.target.value) {
            sessionStorage.setItem([event.target.name], 'HIGH');
            this.setState({ [event.target.name]: 'HIGH', lastInboundSelectedConditionChecked: event.target.value, pageErrorMessage: null, pageSuccessMessage: null })
        }
        else {
            sessionStorage.setItem([event.target.name], 'LOW');
            this.setState({ [event.target.name]: 'LOW', lastInboundSelectedConditionChecked: event.target.value, pageErrorMessage: null, pageSuccessMessage: null })
        }
    }

    clearSearchParams = () => {
        this.setState({
            pageSuccessMessage: null,
            pageErrorMessage: null,
            inboundSelectedInstance: [],
            inboundSelectedNamespace: [],
            inboundSelectedInterface: '',
            LastInboundSelectedDate: '',
            lastInboundSelectedCondition: null,
            lastInboundSelectedConditionChecked: false,
            SelectedItemType: '',
        });
        sessionStorage.clear();
    }

    onRowClick = (e) => {
        if (e.data.Comments) {
            this.setState({ actionCommentsText: e.data.Comments, actionCommentPanelVisible: true })
        } else {
            this.setState({ actionCommentsText: "<i>Nothing has been specified....</i>", actionCommentPanelVisible: true })
        }
    }

    //HM-938 - Bulk update in case of Inbound In-Activity page.
    onSelectionChange = (event) => {
        this.setState({ pageErrorMessage: null, pageErrorMessage: null, selectedRows: event.value })
    }

    //HM-938 - Bulk update in case of Inbound In-Activity page.
    updateSelectedInboundMetrics = () => {
        if (this.state.selectedRows && this.state.selectedRows.length <= global.INCIDENT_MAX_SELECTION) {
            this.setState({
                updateMultipleSelectedDialog: true, pageErrorMessage: null, pageSuccessMessage: null,
                cacheInboundMetricRequest: { ...this.state.cacheInboundMetricRequest, Ids: this.state.selectedRows.map(x => x.Id) }
            });
        }
        else {
            this.setState({ pageErrorMessage: "Maximum " + global.INCIDENT_MAX_SELECTION + " records can be selected at a once" })
        }
    }

    //HM-938 - Bulk update in case of Inbound In-Activity page.
    hideUpdateMultipleIssueDialog = () => {
        this.setState({ updateMultipleSelectedDialog: false, pageErrorMessage: null, pageSuccessMessage: null })
    }

    //HM-938 - Bulk update in case of Inbound In-Activity page.
    handleInputChange = (event) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, pageErrorMessage: null })
        if (event.target.name === "InactivityTimeout") {
            this.setState({
                cacheInboundMetricRequest: {
                    ...this.state.cacheInboundMetricRequest, //using spread operator to initialize object with existing props
                    [event.target.name]: parseInt(event.target.value, 10),
                    ["ExpectedFrequency"]: this.formatSeconds(event.target.value)
                }
            })
        }
        else if (event.target.name === "IsBackloadService" || event.target.name === "IsReviewed"
            || event.target.name === "Decommissioned") {
            this.setState({
                cacheInboundMetricRequest: {
                    ...this.state.cacheInboundMetricRequest, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.checked
                }
            })
        }
        else {
            this.setState({
                cacheInboundMetricRequest: {
                    ...this.state.cacheInboundMetricRequest, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value,
                }
            })
        }
    }

    //HM-938 - Bulk update in case of Inbound In-Activity page.
    validateEditInboundFormField = (event, onSave = false) => {
        this.setState({ pageErrorMessage: '' });
        if (onSave) {

            if (event.EditReason === null || event.EditReason === '') {
                this.setState({ pageErrorMessage: 'Edit Reason is required' });
                return false;
            }
            else if (event.InactivityTimeout === null || event.InactivityTimeout === '' || event.InactivityTimeout <= 0) {
                this.setState({ pageErrorMessage: 'Inbound Threshold Frequency is required' });
                return false;
            } else if (event.EditReason.trim().length < global.COMMENT_BOX_MIN_LENGTH) {
                this.setState({ pageErrorMessage: 'Edit Reason should be atleast of ' + global.COMMENT_BOX_MIN_LENGTH + ' characters.', disableNotificationVisible: false })
            }
            else if (event.EditReason.trim().length > 0 || event.Comments.length > 0) {
                //HM-708 bug fix
                var onlyValidEditReasonCharacters = /^[^'"]*$/.test(event.EditReason.trim());
                var onlyCommentsCharacters = /^[^'"]*$/.test(event.Comments);
                if (!onlyValidEditReasonCharacters || !onlyCommentsCharacters) {
                    this.setState({ pageErrorMessage: "Action Comments/Edit Reason should not contain single (') or double (\") quotes.", disableNotificationVisible: false })
                }
                else {
                    this.setState({ pageErrorMessage: '' });
                    this.editMiltupleInboundData()
                }
            }
        }
    }

    //HM-938 - Bulk update in case of Inbound In-Activity page.
    editMiltupleInboundData() {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })
        let requestBody = JSON.stringify(this.state.cacheInboundMetricRequest)

        this.inboundActivityService.editMiltupleInboundData(this.props.msalContext, requestBody).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                    this.setState({ hasReadRights: false });
                }
                else if (d.status === 200) {
                    this.setState({
                        pageErrorMessage: '', pageSuccessMessage: "Changes saved successfully.", selectedRows: [], updateMultipleSelectedDialog: false,
                        cacheInboundMetricRequest: {
                            Id: 0,
                            InactivityTimeout: 0,
                            IsBackloadService: false,
                            IsReviewed: false,
                            Decommissioned: false,
                            Comments: null,
                            EditReason: null,
                            Ids: []
                        }
                    })
                    this.loadInboundMetricDataOnDemand()
                }
            }
        ).catch(e => {
            this.setState({ hideSpinner: true, pageErrorMessage: 'Something went wrong !!!' })
            console.log(e)
        });
    }

    //HM-938 - Bulk update in case of Inbound In-Activity page.
    formatSeconds(totalseconds) {
        if (totalseconds >= 0) {
            var day = 86400;
            var hour = 3600;
            var minute = 60;
            var daysout = Math.floor(totalseconds / day);
            var hoursout = Math.floor((totalseconds - daysout * day) / hour);
            var minutesout = Math.floor((totalseconds - daysout * day - hoursout * hour) / minute);
            var secondsout = Math.floor(totalseconds - daysout * day - hoursout * hour - minutesout * minute);
            return daysout + "d " + hoursout + "h " + minutesout + "m " + secondsout + "s"
        }
        else
            return "0d 0h 0m 0s"
    }

    //HM-938 - Bulk update in case of Inbound In-Activity page.
    saveFooter = (
        <div>
            <Button label='Save' onClick={() => this.validateEditInboundFormField(this.state.cacheInboundMetricRequest, true)} />
        </div>
    );

    globalSearchFilter = (filtersKey) => {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <span>
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} /> &nbsp;
                        <Button label="Update" icon="pi pi-save" className="p-button-success" visible={this.state.selectedRows.length > 1} onClick={this.updateSelectedInboundMetrics} />
                    </span>

                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)}
                            placeholder="Global Search" style={{ width: '100%' }} size="100" />
                    </span>
                    <span>
                        <Dropdown value={this.state.selectedAutoRefresh} options={this.state.autoRefreshOptions} onChange={this.onAutoRefresh} placeholder="Auto-Refresh"
                            tooltip='Auto-Refresh' tooltipOptions={{ position: 'top' }} className="ui-column-filter" />
                        <Button hidden={this.state.selectedAutoRefresh !== 0} tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.loadInboundMetricDataOnDemand} icon="pi pi-refresh" />
                    </span>
                </div>

                <div style={{ 'textAlign': 'center', 'padding-top': '10px' }}>
                    <span style={{ 'padding-right': '10px' }}>
                        <RadioButton name='IsActive' tooltip='View Inactive Interfaces' tooltipOptions={{ position: 'top' }} onChange={(e) => this.onIsActiveInterfaceCheckChange(e)} checked={sessionStorage.getItem("inActiveInterface") === 'true' ? true : false} /> No-Activity
                    </span>
                    <span style={{ 'padding-right': '10px' }}>
                        <RadioButton name='IsReviewed' tooltip='Reviewed' tooltipOptions={{ position: 'top' }} onChange={(e) => this.onIsReviewedCheckChange(e)} checked={sessionStorage.getItem("isReviewed") === 'true' ? true : false} /> Reviewed
                    </span>
                </div>
            </div>
        )
    }

    clearDataTableFilters = () => {
        this.setState({
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'System': { value: null, matchMode: FilterMatchMode.IN },
                'Instance': { value: null, matchMode: FilterMatchMode.IN },
                'Namespace': { value: null, matchMode: FilterMatchMode.IN },
                'InterfaceName': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            },
            isReviewed: false, inActiveInterface: false,
            InboundMetricList: this.state.inboundMetricTemp,
            selectedRows: [],
            pageErrorMessage: null, pageSuccessMessage: null
        });
        sessionStorage.clear();
    }

    //HM-935 - change to save the filter
    onGlobalFilterChange = (event, filtersKey) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters, pageErrorMessage: null, pageSuccessMessage: null });
    }

    instanceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.InstanceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Instance" className="multiselect-custom"
        />;
    }

    namespaceFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.NamespaceList}
            onChange={(e) => options.filterCallback(e.value, options.index)} filter maxSelectedLabels={1}
            placeholder="Namespace" className="multiselect-custom"
        />;
    }

    setLastInboundDate = (options) => {
        this.setState({
            LastInboundSelectedDate: options.value,
            lastInboundSelectedCondition: this.state.lastInboundSelectedConditionChecked ? "HIGH" : "LOW",
            pageErrorMessage: null, pageSuccessMessage: null
        })
        sessionStorage.setItem("lastInboundSelectedDate", options.value);
        sessionStorage.setItem("lastInboundSelectedCondition", this.state.lastInboundSelectedConditionChecked ? "HIGH" : "LOW");
    }

    //HDO-6801
    validateSearchInputs = () => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        let currentDate = new Date()
        var itemNameInputCheck = /^[^'"]*$/.test(this.state.inboundSelectedInterface);

        if (this.state.inboundSelectedInterface.trim().length > 0 && this.state.inboundSelectedInterface.trim().length < 4) {
            this.setState({ pageErrorMessage: 'Interface Name should be greater than 4 characters.' })
            return false;
        }
        else if (this.state.inboundSelectedInterface.trim().length > 500) {
            this.setState({ pageErrorMessage: 'Interface Name cannot be more than 500 characters.' })
            return false;
        }
        else if (!itemNameInputCheck) {
            this.setState({ pageErrorMessage: "Interface Name should not contain single (') or double (\") quotes." })
            return false;
        }
        else if (this.state.LastInboundSelectedDate > currentDate) {
            this.setState({ pageErrorMessage: "Last inbound date should be less than current date" })
            return false;
        }
        else {
            this.setState({ hideSpinner: false });
            this.loadInboundMetricDataOnDemand();
        }
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        return true;
    }

    onIsReviewedCheckChange(e) {
        if (e.checked) {
            this.setState({ isReviewed: e.checked, InboundMetricList: this.state.inboundMetricTemp.filter(p => p.IsReviewed === e.checked) })
            sessionStorage.setItem("isReviewed", e.checked);
            sessionStorage.setItem("inActiveInterface", false);
        } else {
            this.setState({ isReviewed: e.checked, InboundMetricList: this.state.inboundMetricTemp })
            sessionStorage.setItem("isReviewed", e.checked);
            sessionStorage.setItem("inActiveInterface", false);
        }
    }

    onIsActiveInterfaceCheckChange(e) {
        if (e.checked) {
            this.setState({ isReviewed: false, inActiveInterface: e.checked, InboundMetricList: this.state.inboundMetricTemp.filter(p => p.CurrentFrequencyInSeconds > p.InactivityTimeout) })
            sessionStorage.setItem("inActiveInterface", e.checked);
            sessionStorage.setItem("isReviewed", false);
        } else {
            this.setState({ isReviewed: false, inActiveInterface: e.checked, InboundMetricList: this.state.inboundMetricTemp })
            sessionStorage.setItem("inActiveInterface", e.checked);
            sessionStorage.setItem("isReviewed", false);
        }
    }

    rowClassStyle = (rowData) => {
        if (!rowData.IsReviewed) {
            return rowData.CurrentFrequencyInSeconds > rowData.InactivityTimeout && !rowData.IsBackloadService ? 'p-highlight' : ''
        };
    }

    reviewedBodyTemplate(rowData) {
        let body = ""
        if (rowData.IsReviewed) {
            body = <i className='pi pi-check-circle'></i>;
        } else if (rowData.CurrentFrequencyInSeconds > rowData.InactivityTimeout) {
            body = <i className='pi pi-times-circle'></i>;
        }
        return body;
    }

    getCacheProductionUrl = (rowData) => {
        let url = null
        if (rowData.OSVersion === "AIX") {
            if (rowData.Platform === "ENSEMBLE" || rowData.Platform === "HEALTHSHARE")
                url = `https://${rowData.Instance.toLocaleLowerCase()}.nshs.edu/csp/healthshare/${rowData.Namespace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?NAMESPACE=${rowData.Namespace}`;
            else
                url = `https://${rowData.System.toLocaleLowerCase()}.nshs.edu/${rowData.Instance.toLocaleLowerCase()}/csp/${rowData.Namespace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?NAMESPACE=${rowData.Namespace}`;
        }
        else if (rowData.OSVersion === "LINUX") { //HM-1149 - fix
            url = `https://${rowData.Instance.toLocaleLowerCase()}.mid.northwell.edu/csp/healthshare/${rowData.Namespace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?NAMESPACE=${rowData.Namespace}`;
        }
        return <a href={url} target="_blank">{rowData.Namespace}</a>;
    }

    onAutoRefresh = (e) => {
        let interval = {}
        let selectedAutoRefresh = e.value;
        clearInterval(this.state.intervalId);
        if (e.value && e.value > 0) {
            interval = setInterval(() => {
                this.loadInboundMetricDataOnDemand();
            }, selectedAutoRefresh);
        }
        this.setState({ selectedAutoRefresh: e.value, intervalId: interval })
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    render() {
        let commentsPanel = "";
        if (this.state.actionCommentPanelVisible) {
            commentsPanel = <div>
                <Accordion activeIndex={0}>
                    <AccordionTab header="Action Comments" headerStyle={{ textAlign: 'left' }}>
                        < div className='action-comments' dangerouslySetInnerHTML={{ __html: this.state.actionCommentsText }}></div >
                    </AccordionTab>
                </Accordion>
                <br />
            </div>
        }
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                <div className='inboundcontainer'>

                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}>
                        <Spinner />
                    </div>

                    <div className="inbounddetails">
                        <div>

                        </div>
                        <header className="page-header">
                            <div className="page-header-title">INBOUND IN-ACTIVITY
                                &nbsp;<Tooltip target=".custom-target-icon" />
                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge" data-pr-tooltip="1.Single click on a row to view action comments.&#013; 2.Double click on a row to view/edit delails." data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                            </div>
                        </header>

                        <div className="card">
                            <div className="float-container">
                                <div className="float-child">
                                    <MultiSelect options={this.state.InstanceList} value={this.state.inboundSelectedInstance} name='inboundSelectedInstance'
                                        filter onChange={(e) => this.handleMultiDropDownInputChange(e)} maxSelectedLabels={1} placeholder="Select Instance" className="multiselect-custom" tooltip='Instance' tooltipOptions={{ position: 'top' }}
                                    />
                                </div>
                                <div className="float-child">
                                    <MultiSelect options={this.state.NamespaceList} value={this.state.inboundSelectedNamespace} name='inboundSelectedNamespace'
                                        filter onChange={(e) => this.handleMultiDropDownInputChange(e)} maxSelectedLabels={1} placeholder="Select NameSpace" className="multiselect-custom" tooltip='Namespace' tooltipOptions={{ position: 'top' }}
                                    />
                                </div>
                                <div className="float-child">
                                    <InputText type='text' placeholder='Interface Name' name='inboundSelectedInterface' value={this.state.inboundSelectedInterface} onChange={this.handleSearchInputChange} tooltip='Interface name' tooltipOptions={{ position: 'top' }} />
                                </div>
                                <div className="float-child">
                                    <Calendar id="basic" placeholder='Last Inbound Date' value={this.state.LastInboundSelectedDate} onChange={this.setLastInboundDate} showTime tooltip='Last inbound datetime' tooltipOptions={{ position: 'top' }} ></Calendar>
                                </div>
                                <div className="float-child">
                                    <InputSwitch name='lastInboundSelectedCondition' checked={this.state.lastInboundSelectedConditionChecked} onChange={this.onLastInboundSelectedConditionChecked}
                                        tooltip={this.state.lastInboundSelectedConditionChecked ? 'If checked, search result is greater than selected last inbound datetime' : 'If not checked, search result is less than selected last inbound datetime'} />
                                </div>
                                <div className="float-child">
                                    <Button disabled={this.state.disableSearchButton} label='Search' onClick={() => this.validateSearchInputs()} />
                                </div>
                                <div className="float-child">
                                    <Button label='Clear' onClick={this.clearSearchParams} />
                                </div>
                            </div>
                        </div><br />

                        <DataTable
                            value={this.state.InboundMetricList}
                            paginator={this.state.showpagination}
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20, 35, 50]}
                            dataKey="Id"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')} //HM-935 - change to save the filter
                            rowClassName={this.rowClassStyle}
                            onRowDoubleClick={this.onRowDoubleClick}
                            onRowClick={this.onRowClick}
                            emptyMessage="No Records found."
                            selection={this.state.selectedRows}
                            onSelectionChange={this.onSelectionChange}
                            selectionPageOnly showGridlines removableSort
                            onFilter={(e) => this.setState({ filters: e.filters, selectedRows: [] })}
                            stateStorage="session" stateKey="dt-inbound-inactivity-session"
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }} frozen></Column>
                            <Column field="Id" header="#ID" style={{ textAlign: 'left', width: '60px' }} hidden />
                            <Column field="IsReviewed" header="Reviewed" dataType="boolean" style={{ textAlign: 'center', width: '4rem' }} body={this.reviewedBodyTemplate} />
                            <Column field="Instance" header="Instance" filter filterPlaceholder='Instance' filterElement={this.instanceFilterTemplate} showFilterMatchModes={false} style={{ textAlign: 'left', width: '100px' }} />
                            <Column field="Namespace" header="Namespace" body={this.getCacheProductionUrl} filter filterPlaceholder='Namespace' filterElement={this.namespaceFilterTemplate} showFilterMatchModes={false} style={{ textAlign: 'left', width: '100px' }} />
                            <Column field="InterfaceName" header='Interface Name' filter filterPlaceholder='Interface Name' showFilterOperator={false} style={{ textAlign: 'left', width: '150px' }} />
                            <Column field="TimeCollectedDisplay" sortable sortField="TimeCollected" header="Time Collected (EST)" style={{ textAlign: 'left', width: '140px' }} />
                            <Column field="LastInboundTimeStampDisplay" sortable sortField="LastInboundTimeStamp" header="Last Inbound (EST)" style={{ textAlign: 'left', width: '140px' }} />
                            <Column field="CurrentFrequency" sortable sortField='CurrentFrequencyInSeconds' header="No Activity Since" style={{ textAlign: 'left', width: '130px' }} />
                            <Column field="ExpectedFrequency" sortable sortField='InactivityTimeout' header="Expected Frequency" style={{ textAlign: 'left', width: '140px' }} />
                            <Column field="LastReviewedDateTimeDisplay" sortable sortField='LastReviewedDateTime' header="Reviewed DateTime (EST)" style={{ textAlign: 'left', width: '130px' }} />
                            <Column field="LastReviewedBy" header="Reviewed By" style={{ textAlign: 'left', width: '80px' }} />
                        </DataTable><br />
                        {commentsPanel}
                    </div> <br />

                    {/* HM-938 - Bulk update in case of Inbound In-Activity page. */}
                    <Dialog header='Edit Inbound Details' footer={this.saveFooter} width='750px'
                        visible={this.state.updateMultipleSelectedDialog} modal={true}
                        onHide={() => this.setState({ updateMultipleSelectedDialog: false })} maximizable={false}>
                        <div>
                            <Message severity="warn" text={"Any edit/update will apply to all selected records."} />
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td colSpan={1}>
                                            <Checkbox name='IsReviewed' inputId="cb1" value="Active" onChange={this.handleInputChange} checked={this.state.cacheInboundMetricRequest.IsReviewed}></Checkbox>&nbsp;
                                            <label htmlFor="cb1" className="p-checkbox-label d-inline">Reviewed</label>
                                        </td>
                                        <td colSpan={1}>
                                            <Checkbox name='IsBackloadService' inputId="cb1" value="Active" onChange={this.handleInputChange} checked={this.state.cacheInboundMetricRequest.IsBackloadService}></Checkbox>&nbsp;
                                            <label htmlFor="cb1" className="p-checkbox-label d-inline">Backload Service</label>
                                        </td>
                                        <td colSpan={1}>
                                            <Checkbox name='Decommissioned' inputId="cb1" value="Active" onChange={this.handleInputChange} checked={this.state.cacheInboundMetricRequest.Decommissioned}></Checkbox>&nbsp;
                                            <label htmlFor="cb1" className="p-checkbox-label d-inline">Decommissioned</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <label className='required-field'>Inbound Threshold Frequency (Seconds)</label>
                                            <InputText type='text' name='InactivityTimeout' value={this.state.cacheInboundMetricRequest.InactivityTimeout || 0}
                                                onChange={this.handleInputChange} onBlur={this.validateEditInboundFormField} />
                                        </td>
                                        <td colSpan={2}>
                                            <label>Inbound Threshold Frequency</label>
                                            <label>{this.state.cacheInboundMetricRequest.ExpectedFrequency}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <label>Action Comments
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge" data-pr-tooltip="Use HTML syntax for decorated text" data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <InputTextarea type='text' name='Comments' rows="5" cols="160" value={this.state.cacheInboundMetricRequest.Comments}
                                                onChange={this.handleInputChange} onBlur={this.validateEditInboundFormField} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <label className='required-field'>Edit Reason
                                                &nbsp;<Tooltip target=".custom-target-icon" />
                                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge" data-pr-tooltip={'Edit Reason should be atleast of ' + global.COMMENT_BOX_MIN_LENGTH + ' characters'} data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                                            </label>
                                            <InputTextarea type='text' name='EditReason' rows="5" cols="160" value={this.state.cacheInboundMetricRequest.EditReason}
                                                onChange={this.handleInputChange} onBlur={this.validateEditInboundFormField} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Dialog>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}

const WrappedInboundActivityComponent = withMsal(InboundActivityComponent);
export default withAITracking(reactPlugin, WrappedInboundActivityComponent);