import React, { Component } from 'react';
import { withMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../../AppInsights';
import { InteractionType } from "@azure/msal-browser";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import BreadCrumbComponent from '../bread-crumb/BreadCrumbComponent';
import { ProdItemSettingService } from './ProdItemSettingSerivce'
import { AdminService } from '../admin/AdminService';
import AlertMessage from "../alertmessage/AlertMessage";
import { MessageSeverity } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { Message } from 'primereact/message';
import { Calendar } from 'primereact/calendar';
import Spinner from '../Spinner'
import './ProdItemSetting.css'

//HM-920 - UI show prod item settings and there ip port info
class ProdItemSetting extends Component {
    constructor() {
        super();
        this._isMounted = false;
        this.state = {
            pageSuccessMessage: null,
            pageErrorMessage: null,
            ProdItemSettingsList: [],
            ProdItemQueueDetails: {},
            ProdItemLogs: [],
            SelectedInterface: {},
            InstanceList: [],
            NamespaceList: [],
            ItemStatusList: ["DISABLED", "ENABLED", "ERROR", "INACTIVE", "RETRY", "UNCONFIGURED"], //HM-1193 - bug - made the list static
            hideSpinner: false,
            dt: React.createRef(),
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'ItemStatus': { value: ["DISABLED", "ERROR", "RETRY", "UNCONFIGURED", "INACTIVE"], matchMode: FilterMatchMode.IN }
            },
            settingInHtml: null,
            visibleInterfaceDetails: false,
            disableSearchButton: false,

            visibleConfigDialog: false,
            showRecycleDialog: false,
            showLogsDialog: false,

            ProdItemSettingSelectedInstance: JSON.parse(sessionStorage.getItem("ProdItemSettingSelectedInstance")) ?? '',
            ProdItemSettingSelectedNameSpace: JSON.parse(sessionStorage.getItem("ProdItemSettingSelectedNameSpace")) ?? '',
            ProdItemSettingItemNameInput: sessionStorage.getItem("ProdItemSettingItemNameInput") ?? '',
            SelectedItemType: '',
            fromDate: new Date(new Date().setDate(new Date().getDate() - global.PROD_ITEM_MAX_DATE_RANGE + 1)),
            toDate: new Date(new Date().setHours(23, 59, 59, 999)),
            SearchLogsBtnDisable: false,
            SelectedLogType: 1,

            itemTypes: [
                { label: 'OPERATION', value: 'OPERATION' },
                { label: 'PROCESS', value: 'PROCESS' },
                { label: 'SERVICE', value: 'SERVICE' }
            ],
            priorityList: [
                { label: 'P1', value: 1 },
                { label: 'P2', value: 2 },
                { label: 'P3', value: 3 },
                { label: 'P4', value: 4 }
            ],
            selectedAutoRefresh: 0,
            intervalId: null,
            autoRefreshOptions: [
                { label: 'Auto-Refresh Off', value: 0 },
                { label: '1 minute', value: 60000 },
                { label: '5 minute', value: 300000 },
                { label: '15 minute', value: 900000 },
                { label: '30 minute', value: 1800000 }
            ],
            logType: [
                { label: 'STATUS', value: 1 },
                { label: 'RECYCLE', value: 2 }
            ]
        };

        this.baseAPIUrl = global.ENV_HIE_ADMIN_API_AZURE_URL;
        this.prodItemSettingService = new ProdItemSettingService();
        this.adminService = new AdminService();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getAdminRoles();
        this.getHIEInstances();
    }

    getAdminRoles = () => {
        this.adminService.getAdminRoles(this.props.msalContext).then(
            (d) => {
                this.setState({ adminRoles: d, pageErrorMessage: null });
            }
        ).catch(e => {
            console.log(e)
        });
    }

    getRoleId = () => {
        var adminRoles = this.state.adminRoles;
        if (adminRoles && adminRoles.length > 0) {
            if (adminRoles.includes('Admin.Write'))
                return 2;
            if (adminRoles.includes('Admin.Read'))
                return 1;
        }
        return 0;
    }

    getHIEInstances = () => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })
        this.prodItemSettingService.getHIEInstances("CACHE;AZURE", this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.InstanceList.push({ label: item.ServerInstance, value: item.ServerInstance })));
                        this.setState({ hideSpinner: true });
                        this.getProductions(['ALL'])
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    getProductions = (instance) => {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })

        this.prodItemSettingService.getProductions(instance, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        d.body.map((item) => (this.state.NamespaceList.push({ label: item.Namespace, value: item.Namespace })));
                        this.setState({ hideSpinner: true, disableNameSpaceDropDown: false });
                        this.getCacheProductionItemsHybridStatusV2();
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    getCacheProductionItemsHybridStatusV2 = () => {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })
        this.prodItemSettingService.getCacheProductionItemsHybridStatusV2(this.state.ProdItemSettingSelectedInstance, this.state.ProdItemSettingSelectedNameSpace, this.state.ProdItemSettingItemNameInput,
            this.state.SelectedItemType, this.props.msalContext).then(
                (d) => {
                    if (d.status === 403) {
                        this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                    } else {
                        if (this._isMounted) {
                            this.setState({
                                ProdItemSettingsList: d.body,
                                //ItemStatusList: [...new Set(d.body.map(x => x.ItemStatus))].sort((a, b) => { return a > b ? 1 : -1 }), //HM-1193 - bug - made the list static
                                hideSpinner: true
                            });
                        }
                    }
                }
            ).catch(e => {
                console.log(e)
                this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
            });
    }

    getCacheProductionItemById = (Id, showRecycleDialog) => {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })
        this.prodItemSettingService.getCacheProductionItemsHybridStatusById(Id, !showRecycleDialog, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({
                            SelectedInterface: d.body,
                            hideSpinner: true,
                            showRecycleDialog: showRecycleDialog
                        });
                        if (showRecycleDialog) {

                            let items = [...this.state.ProdItemSettingsList]
                            let updatedItems = items.map(obj => {
                                if (obj.Id === Id)
                                    return { ...obj, Enabled: d.body.Enabled, ItemStatus: d.body.ItemStatus }
                                return obj;
                            });
                            this.setState({ ProdItemSettingsList: updatedItems })
                        } else
                            this.onRowDoubleClick(d.body);
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    //HDO-5492 - PI 2023 Q3 Recycle Interface - Monitoring Tool UI
    recycleInterface(Action) {
        this.setState({ hideSpinner: false, pageErrorMessage: null, pageSuccessMessage: null })
        let Id = this.state.SelectedInterface.Id
        let body = JSON.stringify(this.state.SelectedInterface);
        this.prodItemSettingService.recycleInterface(Id, body, Action, this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this page.', hideSpinner: true });
                } else {
                    if (this._isMounted) {

                        let items = [...this.state.ProdItemSettingsList]
                        let updatedItems = items.map(obj => {
                            if (obj.Id === Id) {
                                if (d.body === 1 && Action === "stop")
                                    return { ...obj, Enabled: false, ItemStatus: "DISABLED" }
                                else if (d.body === 1)
                                    return { ...obj, Enabled: true, ItemStatus: "ENABLED" }
                            }
                            return obj;
                        });

                        this.setState({
                            ProdItemSettingsList: updatedItems,
                            pageSuccessMessage: d.body === 1 ? "Interface " + Action + " is successful" : "",
                            pageErrorMessage: d.body !== 1 ? "Interface " + Action + " failed." : "",
                            hideSpinner: true, showRecycleDialog: false
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    getQueueInterfaceDetails(request) {
        this.setState({ pageSuccessMessage: null, pageErrorMessage: null, hideSpinner: false })
        this.prodItemSettingService.getQueueInterfaceDetails(request.Instance, request.NameSpace, request.Name, this.props.msalContext).then(
            (d) => {
                console.log(d)
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this detail.', hideSpinner: true });
                }
                else if (d.status !== 200) {
                    this.setState({ pageErrorMessage: d.body.errorcode, hideSpinner: true });
                }
                else {
                    if (this._isMounted) {
                        this.setState({
                            ProdItemQueueDetails: d.body,
                            hideSpinner: true,
                            visibleConfigDialog: true
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    //HDO-6006 - method to get prod item status & recycle logs
    getProductionItemLogs = (rowData) => {
        let fromDateLocal = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(this.state.fromDate)
        let toDateLocal = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(this.state.toDate)
        this.setState({ SelectedInterface: rowData, pageSuccessMessage: null, pageErrorMessage: null, hideSpinner: false })

        this.prodItemSettingService.getProdItemLogs(rowData.Id, rowData.Instance, rowData.NameSpace, rowData.Name, fromDateLocal, toDateLocal, this.state.SelectedLogType, this.getClientTimeZone(), this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to view this detail.', hideSpinner: true });
                } else if (d.status === 200) {
                    if (this._isMounted) {
                        this.setState({
                            ProdItemLogs: d.body,
                            hideSpinner: true,
                            showLogsDialog: true
                        });
                    }
                }
                else
                    this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    //HDO-6034 - bug fix - method to get the client timezone and send it to backend API to query in proper datetime format
    getClientTimeZone = () => {
        var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

        switch (clientTimeZone) {
            case "Asia/Calcutta":
                return "India Standard Time";
            case "America/New_York":
                return "Eastern Standard Time";
        }
    }

    updateQueueAlertThresholdValues(ProdItemQueueDetails) {
        this.setState({ hideSpinner: false })
        let requestBody = {
            QalertPriority: ProdItemQueueDetails.QalertPriority,
            QcountAlertValue: ProdItemQueueDetails.QcountAlertValue || 0,
            LastMessageDelayAlertValue: ProdItemQueueDetails.LastMessageDelayAlertValue || 0,
        }
        this.prodItemSettingService.updateQueueAlertThresholdValues(ProdItemQueueDetails.Id, JSON.stringify(requestBody), this.props.msalContext).then(
            (d) => {
                if (d.status === 403) {
                    this.setState({ pageErrorMessage: 'You do not have permission to update.', hideSpinner: true });
                } else {
                    if (this._isMounted) {
                        this.setState({
                            ProdItemQueueDetails: d.body,
                            hideSpinner: true,
                            visibleConfigDialog: true,
                            confirmEditVisible: false,
                            pageSuccessMessage: "Threshold Values updated successfully"
                        });
                    }
                }
            }
        ).catch(e => {
            console.log(e)
            this.setState({ pageErrorMessage: 'Something went wrong !', hideSpinner: true });
        });
    }

    arIntVals = ['Priority', 'LastMessageDelayAlertValue', 'QcountAlertValue'];

    handleInputChange = (event) => {
        sessionStorage.setItem([event.target.name], event.target.value);
        this.setState({ [event.target.name]: event.target.value, pageErrorMessage: null, pageSuccessMessage: null })

        let found = false;
        for (let i = 0; i < this.arIntVals.length; i++) {
            if (event.target.name === this.arIntVals[i]) {
                found = true;
                this.setState({
                    ProdItemQueueDetails: {
                        ...this.state.ProdItemQueueDetails, //using spread operator to initialize object with existing props
                        [event.target.name]: parseInt(event.target.value, 10)
                    }
                })
                break;
            }
        }

        if (!found) {
            this.setState({
                ProdItemQueueDetails: {
                    ...this.state.ProdItemQueueDetails, //using spread operator to initialize object with existing props
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    handleDropDownInputChange = (event) => {
        sessionStorage.setItem([event.target.name], event.value);
        this.setState({ [event.target.name]: event.value, pageErrorMessage: null, pageSuccessMessage: null })
    }

    handleMultiDropDownInputChange = (event) => {
        sessionStorage.setItem([event.target.name], JSON.stringify(event.value));
        this.setState({ [event.target.name]: event.value, pageErrorMessage: null, pageSuccessMessage: null })
    }

    validateInputFields = () => {
        this.setState({ pageErrorMessage: '', pageSuccessMessage: '' })

        var itemNameInputCheck = /^[^'"]*$/.test(this.state.ProdItemSettingItemNameInput);

        if (this.state.ProdItemSettingItemNameInput.trim().length > 0 && this.state.ProdItemSettingItemNameInput.trim().length < 5) {
            this.setState({ pageErrorMessage: 'Interface Name should be greater than 5 characters.' })
            return false;
        }
        else if (this.state.ProdItemSettingItemNameInput.trim().length > 500) {
            this.setState({ pageErrorMessage: 'Interface Name cannot be more than 500 characters.' })
            return false;
        }
        else if (this.state.ProdItemSettingItemNameInput.trim().length > 500) {
            this.setState({ pageErrorMessage: 'Interface Name cannot be more than 500 characters.' })
            return false;
        }
        else if (!itemNameInputCheck) {
            this.setState({ pageErrorMessage: "Interface Name should not contain single (') or double (\") quotes." })
            return false;
        }
        else
            this.getCacheProductionItemsHybridStatusV2()
        return true;
    }

    getProdItemStatus(rowData) {
        if (rowData.Enabled && rowData.ItemStatus === "ENABLED") {
            return <span className={`issue-status-badge status-enabled`}>{rowData.ItemStatus}</span>;
        }
        else if (!rowData.Enabled) {
            return <span className={`issue-status-badge status-disabled`}>{rowData.ItemStatus}</span>;
        }
        else if (rowData.ItemStatus === "RETRY") {
            return <span className={`issue-status-badge status-retry`}>{rowData.ItemStatus}</span>;
        }
        else if (rowData.ItemStatus)
            return <span className={`issue-status-badge status-disabled`}>{rowData.ItemStatus}</span>;
        else
            return "N.A"
    }

    formatSeconds(rowData, column) {
        let totalseconds = column.field === null || column.field === undefined ? rowData[column] : rowData[column.field];

        if (rowData.MetricType === 106)
            return totalseconds > 0 ? "DLQ - " + totalseconds : totalseconds

        if (rowData.MetricType === 6 && totalseconds >= 0) {
            var day = 86400;
            var hour = 3600;
            var minute = 60;
            var daysout = Math.floor(totalseconds / day);
            var hoursout = Math.floor((totalseconds - daysout * day) / hour);
            var minutesout = Math.floor((totalseconds - daysout * day - hoursout * hour) / minute);
            var secondsout = Math.floor(totalseconds - daysout * day - hoursout * hour - minutesout * minute);
            return daysout + "d " + hoursout + "h " + minutesout + "m " + secondsout + "s"
        }
        else
            return "0d 0h 0m 0s"
    }

    rowClassStyle = (rowData) => {
        return rowData.ItemStatus === "ERROR" ? 'error-highlight' : ''
    }

    clearSearchParams = () => {
        this.setState({
            pageSuccessMessage: null,
            pageErrorMessage: null,
            ProdItemSettingSelectedInstance: [],
            ProdItemSettingSelectedNameSpace: [],
            ProdItemSettingItemNameInput: '',
            SelectedItemType: '',
        });
        sessionStorage.clear();
    }

    clearDataTableFilters = () => {
        this.setState({
            pageSuccessMessage: null,
            pageErrorMessage: null,
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'ItemStatus': { value: null, matchMode: FilterMatchMode.IN }
            }
        });
        sessionStorage.clear();
    }

    //HM-935 - change to save the filter
    onGlobalFilterChange = (event, filtersKey) => {
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null })

        const value = event.target.value;
        let filters = { ...this.state[filtersKey] };
        filters['global'].value = value;

        this.setState({ [`${filtersKey}`]: filters });
    }

    globalSearchFilter = (filtersKey) => {
        return (
            <div>
                <div className="flex justify-content-between" >
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={this.clearDataTableFilters} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText type="search" value={this.state[`${filtersKey}`]['global'].value || ''} onChange={(e) => this.onGlobalFilterChange(e, filtersKey)}
                            placeholder="Global Search" style={{ width: '100%' }} size={100} />
                    </span>
                    <span>
                        <Dropdown value={this.state.selectedAutoRefresh} options={this.state.autoRefreshOptions} onChange={this.onAutoRefresh} placeholder="Auto-Refresh"
                            tooltip='Auto-Refresh' tooltipOptions={{ position: 'top' }} className="ui-column-filter" />
                        <Button hidden={this.state.selectedAutoRefresh !== 0} tooltip='Refresh' tooltipOptions={{ position: 'top' }} onClick={this.getCacheProductionItemsHybridStatusV2} icon="pi pi-refresh" />
                    </span>
                </div>
            </div>
        )
    }

    showAlertMessage(severity, alertMsg) {
        this.alertMsg.showAlert(severity, alertMsg);
    }

    onRowDoubleClick = (SelectedInterface) => {
        if (SelectedInterface.Settings) {
            try {
                let jsonSetting = []
                let parsedJson = JSON.parse(SelectedInterface.Settings)
                jsonSetting.push(parsedJson)
                var convertSettingsToHtml = jsonSetting.map((Member) => {
                    let keys = Object.keys(Member)
                    let divs = keys.map(k => <><tr><td class="fluidcell">{k}</td><td class="fluidcell">{Member[k]}</td></tr></>)
                    return (
                        <>{divs}</>
                    )
                });
            } catch (error) { }
        }
        this.setState({ visibleInterfaceDetails: true, settingInHtml: convertSettingsToHtml });
    }

    showNAIfNoData = (rowData, column) => {
        let value = rowData[column.field];

        if (value)
            return rowData[column.field];
        else
            return "N.A"
    }

    itemStatusFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={this.state.ItemStatusList}
            onChange={(e) => options.filterCallback(e.value, options.index)} maxSelectedLabels={1}
            placeholder="ItemStatus" className="multiselect-custom"
        />;
    }

    getUrlSessionId = (rowData) => {
        let url = null
        if (rowData.OSVersion === "AIX") {
            if (rowData.Platform === "ENSEMBLE" || rowData.Platform === "HEALTHSHARE")
                url = `https://${rowData.Instance.toLocaleLowerCase()}.nshs.edu/csp/healthshare/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.VisualTrace.zen?SESSIONID=${rowData.SessionId}`;
            else
                url = `https://${rowData.Server.toLocaleLowerCase()}.nshs.edu/${rowData.Instance.toLocaleLowerCase()}/csp/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.VisualTrace.zen?SESSIONID=${rowData.SessionId}`;
        }
        else if (rowData.OSVersion === "LINUX") { //HM-1149 - fix
            url = `https://${rowData.Instance.toLocaleLowerCase()}.mid.northwell.edu/csp/healthshare/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.VisualTrace.zen?SESSIONID=${rowData.SessionId}`;
        }
        return <a href={url} target="_blank">{rowData.SessionId}</a>;
    }

    getCacheProductionUrl = (rowData) => {
        let url = null
        if (rowData.OSVersion === "AIX") {
            if (rowData.Platform === "ENSEMBLE" || rowData.Platform === "HEALTHSHARE")
                url = `https://${rowData.Instance.toLocaleLowerCase()}.nshs.edu/csp/healthshare/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
            else
                url = `https://${rowData.Server.toLocaleLowerCase()}.nshs.edu/${rowData.Instance.toLocaleLowerCase()}/csp/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
        }
        else if (rowData.OSVersion === "LINUX") { //HM-1149 - fix
            url = `https://${rowData.Instance.toLocaleLowerCase()}.mid.northwell.edu/csp/healthshare/${rowData.NameSpace.toLocaleLowerCase()}/EnsPortal.ProductionConfig.zen?PRODUCTION=${rowData.ProductionName}`;
        }
        return <a href={url} target="_blank">{rowData.NameSpace}</a>;
    }

    getActionItems = (rowData) => {
        let url = null
        if (rowData.Platform === "ENSEMBLE")
            url = `http://${global.IACL_SERVER}/csp/sds/CUSTOM.ZenIACL.Public.EnsembleIACLView.cls?interface=${rowData.Name}&namespace=${rowData.NameSpace.toLocaleUpperCase()}`
        else if (rowData.Platform === "HEALTHSHARE")
            url = `http://${global.IACL_SERVER}/csp/sds/CUSTOM.ZenIACL.Public.HIEIACLView.cls?interface=${rowData.Name}&namespace=${rowData.NameSpace.toLocaleUpperCase()}`

        return (
            <React.Fragment>
                <Button disabled={url ? false : true} icon="pi pi-globe" className="p-button-rounded p-button-outlined" tooltip="ICAL" tooltipOptions={{ position: 'top' }} onClick={() => window.open(url, "_blank")} />
                <Button disabled={rowData.MetricType === 106 ? true : false} icon="pi pi-replay" className="p-button-rounded p-button-outlined" tooltip="Recycle" tooltipOptions={{ position: 'top' }} onClick={(e) => this.getCacheProductionItemById(rowData.Id, true)} />
                <Button disabled={rowData.MetricType === 106 ? true : false} icon="pi pi-book" className="p-button-rounded p-button-outlined" tooltip="Logs" tooltipOptions={{ position: 'top' }} onClick={(e) => this.getProductionItemLogs(rowData)} />
                {this.getRoleId() === 2 ? <>
                    <Button icon="pi pi-cog" className="p-button-rounded p-button-help p-button-outlined" tooltip="Config" tooltipOptions={{ position: 'top' }} onClick={(e) => this.getQueueInterfaceDetails(rowData)} />
                </> : <></>}
            </React.Fragment>
        );
    }

    onPriorityChange = (priority) => {
        this.setState({ ProdItemQueueDetails: { ...this.state.ProdItemQueueDetails, QalertPriority: priority.value }, pageErrorMessage: null, pageSuccessMessage: null })
    }

    confirmEditFooter = (
        <div>
            <Button label='Ok' onClick={() => this.updateQueueAlertThresholdValues(this.state.ProdItemQueueDetails)} />
            <Button label='Cancel' onClick={e => this.setState({ confirmEditVisible: false, pageErrorMessage: null, pageSuccessMessage: null })} className='p-button-secondary' />
        </div>
    );

    recycleDialogFooter(SelectedInterface) {
        let html = ""
        if (SelectedInterface.PoolSize === 0) {
            html = <div><Button label='Ok' onClick={() => this.setState({ showRecycleDialog: false })} /></div>
        } else {
            switch (SelectedInterface.ItemStatus) {
                case 'DISABLED':
                    html = <div>
                        <Button label='Enable' className='p-button-success' onClick={(e) => this.recycleInterface("start")} />
                        <Button label='Cancel' onClick={e => this.setState({ showRecycleDialog: false, pageErrorMessage: null, pageSuccessMessage: null })} className='p-button-secondary' />
                    </div>
                    break;
                case 'ENABLED':
                case 'RETRY':
                case 'ERROR':
                case 'INACTIVE':
                    html = SelectedInterface.Platform === "AZURE" ? html = <div><Button label='Ok' onClick={() => this.setState({ showRecycleDialog: false })} /></div> :
                        <div>
                            <Button label='Restart' className='p-button-help' onClick={(e) => this.recycleInterface("restart")} />
                            <Button label='Disable' className='p-button-danger' onClick={(e) => this.recycleInterface("stop")} />
                            <Button label='Cancel' onClick={e => this.setState({ showRecycleDialog: false, pageErrorMessage: null, pageSuccessMessage: null })} className='p-button-secondary' />
                        </div>
                    break;
                default:
                    break;
            }
        }
        return (
            <>{html}</>
        );
    }

    onAutoRefresh = (e) => {
        let interval = {}
        let selectedAutoRefresh = e.value;
        clearInterval(this.state.intervalId);

        if (e.value && e.value > 0) {
            interval = setInterval(() => {
                this.getCacheProductionItemsHybridStatusV2();
            }, selectedAutoRefresh);
        }
        this.setState({ selectedAutoRefresh: e.value, intervalId: interval })
    }

    setToDate(date) {
        //HM-707 bug fix - if same date, then use the current datetime, else set hours to max of that day.
        if (date.getDate() === new Date().getDate()) {
            this.setState({ toDate: new Date(date.setHours(23, 59, 59, 999)) })
            this.validateFilterDates(this.state.fromDate, new Date(date.setHours(23, 59, 59, 999)))
        } else {
            this.setState({ toDate: new Date(date.setHours(23, 59, 59, 999)) })
            this.validateFilterDates(this.state.fromDate, date)
        }
    }

    setFromDate(date) {
        this.setState({ fromDate: date })
        this.validateFilterDates(date, this.state.toDate)
    }

    getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2.getTime() - date1.getTime());
        return Math.floor(diffInMs / (1000 * 3600 * 24)); //Math.floor() - HM-693 fix
    }

    validateFilterDates(fromDate, toDate) {
        console.log(fromDate)
        console.log(toDate)
        var currentDate = new Date().setHours(23, 59, 59, 999);
        if (fromDate > toDate) {
            this.setState({ pageErrorMessage: "From Date should be less than To Date", SearchLogsBtnDisable: true })
            return false;
        }
        else if (fromDate > currentDate) {
            this.setState({ pageErrorMessage: "From Date should be less than current date", SearchLogsBtnDisable: true })
            return false;
        }
        else if (toDate > currentDate) {
            this.setState({ pageErrorMessage: "To Date should be less than current date", SearchLogsBtnDisable: true })
            return false;
        }
        else if (this.getDifferenceInDays(fromDate, toDate) > global.PROD_ITEM_MAX_DATE_RANGE - 1) { //-1 - to exclude the extra day, after diff - HM-693 fix
            this.setState({ pageErrorMessage: "Date range cannot exceed more than " + global.PROD_ITEM_MAX_DATE_RANGE + " days", SearchLogsBtnDisable: true })
            return false;
        }
        this.setState({ pageErrorMessage: null, pageSuccessMessage: null, SearchLogsBtnDisable: false })
        return true;
    }

    onShowLogsDialogHide = () => {
        this.setState({
            fromDate: new Date(new Date().setDate(new Date().getDate() - global.PROD_ITEM_MAX_DATE_RANGE + 1)),
            toDate: new Date(new Date().setHours(23, 59, 59, 999)),
            SearchLogsBtnDisable: false,
            SelectedLogType: 1,
            pageSuccessMessage: null, pageErrorMessage: null, showLogsDialog: false, ProdItemLogs: []
        })
    }

    render() {
        return (
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <div className="adminContainer">
                    <BreadCrumbComponent {...this.props} />
                    <AlertMessage ref={instance => { this.alertMsg = instance }} />

                    {this.state.pageErrorMessage ? this.showAlertMessage(MessageSeverity.ERROR, this.state.pageErrorMessage) : <></>}
                    {this.state.pageSuccessMessage ? this.showAlertMessage(MessageSeverity.SUCCESS, this.state.pageSuccessMessage) : <></>}

                    <div hidden={this.state.hideSpinner}><Spinner /></div>

                    <div>
                        <header className="page-header">
                            <div className="page-header-title">PRODUCTION ITEMS
                                &nbsp;<Tooltip target=".custom-target-icon" />
                                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                                    data-pr-tooltip="DLQ - Dead-letter queue in azure service bus."
                                    data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2"
                                    style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
                            </div>
                        </header>

                        <div className="card">
                            <div className="float-container">
                                <div className="float-child">
                                    <MultiSelect options={this.state.InstanceList} value={this.state.ProdItemSettingSelectedInstance} name='ProdItemSettingSelectedInstance'
                                        filter onChange={(e) => this.handleMultiDropDownInputChange(e)} maxSelectedLabels={1} placeholder="Select Instance" className="multiselect-custom"
                                    />
                                </div>
                                <div className="float-child">
                                    <MultiSelect options={this.state.NamespaceList} value={this.state.ProdItemSettingSelectedNameSpace} name='ProdItemSettingSelectedNameSpace'
                                        filter onChange={(e) => this.handleMultiDropDownInputChange(e)} maxSelectedLabels={1} placeholder="Select NameSpace" className="multiselect-custom"
                                    />
                                </div>
                                <div className="float-child">
                                    <InputText type='text' placeholder='Interface Name' name='ProdItemSettingItemNameInput' value={this.state.ProdItemSettingItemNameInput} onChange={this.handleInputChange} />
                                </div>
                                {/* <div className="float-child">
                                    <Dropdown className="ui-column-filter" placeholder="Select Type" value={this.state.SelectedItemType}
                                        name='SelectedItemType' options={this.state.itemTypes} onChange={(e) => this.handleDropDownInputChange(e)} />
                                </div> */}
                                <div className="float-child">
                                    <Button disabled={this.state.disableSearchButton} label='Search' onClick={() => this.validateInputFields()} />
                                </div>
                                <div className="float-child">
                                    <Button label='Clear' onClick={this.clearSearchParams} />
                                </div>
                            </div>
                        </div>
                        <br />
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.ProdItemSettingsList}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            dataKey="Id"
                            filters={this.state.filters}
                            filterDisplay="menu"
                            responsiveLayout="scroll"
                            header={this.globalSearchFilter('filters')}
                            showGridlines removableSort
                            rowClassName={this.rowClassStyle}
                            emptyMessage="No Records found."
                            onFilter={(e) => this.setState({ filters: e.filters, pageErrorMessage: null, pageSuccessMessage: null })} //HM-935 - change to save the filter
                            stateStorage="session" stateKey="dt-proditemsettings-session"
                            onRowDoubleClick={(e) => this.getCacheProductionItemById(e.data.Id, false)}
                            sortField='LastMessageDelay' sortOrder="1"
                        >
                            <Column field="Instance" header="Instance" style={{ textAlign: 'left' }} />
                            <Column field="NameSpace" header="Namespace" style={{ textAlign: 'left' }} body={this.getCacheProductionUrl} />
                            <Column field="Name" header="Interface Name" style={{ textAlign: 'left' }} />
                            <Column field="ItemStatus" header="Status" filter filterPlaceholder='Status' filterElement={this.itemStatusFilterTemplate}
                                showFilterMatchModes={false} body={this.getProdItemStatus} style={{ textAlign: 'left', minWidth: '100px' }} />
                            <Column field="Qcount" header="Qcount" style={{ textAlign: 'left' }} sortable />
                            <Column field="LastMessageDelay" header="Qdelay" style={{ textAlign: 'left' }} body={this.formatSeconds} sortable sortField='LastMessageDelay' />
                            {this.getRoleId() === 2 ? <Column header="Action" alignHeader='center' style={{ textAlign: 'center', width: '260px' }} body={this.getActionItems} />
                                : <Column header="Action" alignHeader='center' style={{ textAlign: 'center', width: '220px' }} body={this.getActionItems} />}
                        </DataTable>

                        {/* Dialog to show prod item settings and details */}
                        <Dialog header={'Interface - ' + this.state.SelectedInterface.Name} style={{ width: '50vw' }}
                            visible={this.state.visibleInterfaceDetails} modal={true}
                            onHide={e => this.setState({ visibleInterfaceDetails: false })} maximizable={true} closeOnEscape={true}>
                            <div>
                                <table class="fixedtablelayout" border={1}>
                                    <th colSpan={2} class="th-details">General Information</th>
                                    <tr><td>Type:</td><td>{this.state.SelectedInterface.ComponentType || "N.A"}</td></tr>
                                    <tr><td>Description:</td><td class="fluidcell">{this.state.SelectedInterface.Description || "N.A"}</td></tr>
                                    <tr><td>Production:</td><td class="fluidcell">{this.state.SelectedInterface.ProductionName || "N.A"}</td></tr>
                                    <tr><td>Class Name:</td><td class="fluidcell">{this.state.SelectedInterface.ClassName || "N.A"}</td></tr>
                                    <tr><td>PoolSize:</td><td>{this.state.SelectedInterface.PoolSize}</td></tr>
                                    <tr><td>Schedule:</td><td>{this.state.SelectedInterface.Schedule || "N.A"}</td></tr>
                                </table>
                            </div>
                            {this.state.SelectedInterface.TimeLogged ? <div>
                                <br />
                                <table class="fixedtablelayout" border={1}>
                                    <th colSpan={2} class="th-details">Event Log</th>
                                    <tr><td>Logtime:</td><td>{this.state.SelectedInterface.TimeLogged || "N.A"}</td></tr>
                                    <tr><td>Text:</td><td class="fluidcell">{this.state.SelectedInterface.Text || "N.A"}</td></tr>
                                    <tr><td>SessionId:</td><td class="fluidcell">{this.state.SelectedInterface.SessionId ? this.getUrlSessionId(this.state.SelectedInterface) : "N.A"}</td></tr>
                                </table>
                            </div> : <></>}
                            {this.state.settingInHtml ? <><br></br><table class="fixedtablelayout" border={1}>
                                <th colSpan={2} class="th-details">Settings</th>{this.state.settingInHtml}</table></> : <></>}
                        </Dialog>

                        {/* Dialog to show thresold values for Queue count, delay and DLQ */}
                        <Dialog header={'Interface - ' + this.state.ProdItemQueueDetails.Name} style={{ width: '30vw' }}
                            visible={this.state.visibleConfigDialog} modal={true}
                            onHide={e => this.setState({ pageSuccessMessage: null, pageErrorMessage: null, visibleConfigDialog: false })} maximizable={true} closeOnEscape={true}>
                            <table>
                                <tr>
                                    <td>
                                        <label><b>Priority</b></label>
                                        <Dropdown style={{ width: '100%' }} className="ui-column-filter" placeholder="Priority" value={this.state.ProdItemQueueDetails.QalertPriority}
                                            options={this.state.priorityList} onChange={this.onPriorityChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label><b>QCount Alert Value</b></label>
                                        <InputText onChange={this.handleInputChange} type='text' name='QcountAlertValue' value={this.state.ProdItemQueueDetails.QcountAlertValue || 0}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label><b>{this.state.ProdItemQueueDetails.MetricType === 106 ? 'Dead-letter QCount Alert Value' : 'Last Message Delay Alert Value - ' + this.formatSeconds(this.state.ProdItemQueueDetails, "LastMessageDelayAlertValue")}</b></label>
                                        <InputText type='text' name='LastMessageDelayAlertValue' onChange={this.handleInputChange} value={this.state.ProdItemQueueDetails.LastMessageDelayAlertValue || 0}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Button label='Save' onClick={() => this.setState({ confirmEditVisible: true, pageErrorMessage: null, pageSuccessMessage: null })} /> &nbsp;
                                        <Button label='Cancel' onClick={() => this.setState({ pageSuccessMessage: null, pageErrorMessage: null, visibleConfigDialog: false })} />
                                    </td>
                                </tr>
                            </table>
                        </Dialog>

                        {/* confirmation dialog box*/}
                        <Dialog header='CONFIRM' footer={this.confirmEditFooter}
                            visible={this.state.confirmEditVisible} width='400px' modal={true}
                            onHide={e => this.setState({ confirmEditVisible: false, pageErrorMessage: null, pageSuccessMessage: null })} maximizable={false}>
                            <div>
                                Are you sure you want to update the threshold values for <b>{this.state.ProdItemQueueDetails.Name}</b>?
                            </div>
                        </Dialog>

                        {/* Dialog to show recycle interface options */}
                        <Dialog header={'Interface - ' + this.state.SelectedInterface.Name} style={{ width: '30vw' }}
                            footer={this.recycleDialogFooter(this.state.SelectedInterface)}
                            visible={this.state.showRecycleDialog} modal={true}
                            onHide={e => this.setState({ pageSuccessMessage: null, pageErrorMessage: null, showRecycleDialog: false })} closeOnEscape={true}>
                            <div>
                                {this.state.SelectedInterface.PoolSize === 0 ?
                                    <>
                                        <Message severity="warn" text="Warning" /><br />
                                        The interface has a 'PoolSize' of 0. It is not possible to restart or disable it from here. <br />
                                        {"Please login into the box " + this.state.SelectedInterface.Instance + " to take any action."}
                                    </>
                                    :
                                    this.state.SelectedInterface.Platform === "AZURE" && this.state.SelectedInterface.ItemStatus === "ENABLED" ?
                                        <>This is an azure service & item is {this.state.SelectedInterface.ItemStatus}. Please reach out to on-call to perform any troubleshooting.</> :
                                        <>
                                            The current status of the item is : {this.state.SelectedInterface.ItemStatus}. <br />
                                            {this.state.SelectedInterface.ItemStatus === "DISABLED" ? <>Do you want to enable item {this.state.SelectedInterface.Name} ?</>
                                                : <>Do you want to Restart or Disable item {this.state.SelectedInterface.Name} ?</>}
                                        </>
                                }
                            </div>
                        </Dialog>

                        {/* HDO-6006 - Dialog to show interface recycle & status logs */}
                        <Dialog header={'Interface - ' + this.state.SelectedInterface.Name + ' - Logs'} style={{ width: '70vw' }}
                            visible={this.state.showLogsDialog} modal={true} maximizable={true}
                            onHide={this.onShowLogsDialogHide} closeOnEscape={true}>
                            <div>
                                <div className="card">
                                    <div className="float-container">
                                        <div className="float-child">
                                            <label htmlFor="basic">Log Type:</label>
                                            <Dropdown value={this.state.SelectedLogType} options={this.state.logType} onChange={(e) => { this.setState({ SelectedLogType: e.value, ProdItemLogs: [] }) }} placeholder="LogType"
                                                tooltip='LogType' tooltipOptions={{ position: 'top' }} className="ui-column-filter" />
                                        </div>
                                        <div className="float-child">
                                            <label htmlFor="basic">From Date:</label>
                                            <Calendar id="basic" value={this.state.fromDate} onChange={(e) => this.setFromDate(e.value)}></Calendar>
                                        </div>
                                        <div className="float-child">
                                            <label htmlFor="basic">To Date:</label>
                                            <Calendar id="basic" value={this.state.toDate} onChange={(e) => this.setToDate(e.value)}></Calendar>
                                        </div>
                                        <div className="float-child">
                                            <label htmlFor="basic"><br></br></label>
                                            <Button disabled={this.state.SearchLogsBtnDisable} label='Fetch' icon='pi pi-arrow-down' onClick={() => this.getProductionItemLogs(this.state.SelectedInterface)} />
                                        </div>
                                    </div>
                                </div>
                                <br />

                                {this.state.SelectedLogType === 1 ?
                                    <DataTable value={this.state.ProdItemLogs}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        filterDisplay="menu"
                                        responsiveLayout="scroll"
                                        showGridlines removableSort
                                        emptyMessage="No Records found."
                                        sortField='TimeCollected' sortOrder="-1"
                                    >
                                        <Column field="TimeCollectedValue" header="TimeCollected" style={{ textAlign: 'left' }} sortable sortField='TimeCollected' />
                                        <Column field="ItemEnabled" header="ItemEnabled" style={{ textAlign: 'left' }} />
                                        <Column field="ItemStatus" header="ItemStatus" style={{ textAlign: 'left' }} />
                                    </DataTable>
                                    : <></>
                                }
                                {this.state.SelectedLogType === 2 ?
                                    <DataTable value={this.state.ProdItemLogs}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        filterDisplay="menu"
                                        responsiveLayout="scroll"
                                        showGridlines removableSort
                                        emptyMessage="No Records found."
                                        sortField='Timestamp' sortOrder="-1"
                                    >
                                        <Column field="TimestampValue" header="Timestamp" style={{ textAlign: 'left' }} sortable sortField='Timestamp' />
                                        <Column field="ActionType" header="Action" style={{ textAlign: 'left' }} />
                                        <Column field="ActionBy" header="Action By" style={{ textAlign: 'left' }} />
                                        <Column field="APIResponse" header="APIResponse" style={{ textAlign: 'left' }} />
                                    </DataTable> : <></>
                                }
                            </div>
                        </Dialog>
                    </div>
                </div >
            </MsalAuthenticationTemplate >
        );
    }
};
const WrappedProdItemSettingComponent = withMsal(ProdItemSetting);
export default withAITracking(reactPlugin, WrappedProdItemSettingComponent);