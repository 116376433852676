import { msalInstance } from '../../App';

export class InboundActivityService {

    async invokeWebAPI(url, httpMethod, msalContext, body = "") {

        // check if msalContext exists
        if (msalContext.accounts.length > 0) {
            const tokenRequest = {
                scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                account: msalContext.accounts[0],
            };

            // acquire Bearer token to include in GET request
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            const token = tokenResponse.accessToken;
            const bearer = `Bearer ${token}`;
            const headers = new Headers();
            headers.append('Accept', '*/*');
            headers.append('Content-Type', 'application/json');
            headers.append('x-northwell-version', '1.0');
            headers.append("Authorization", bearer);
            headers.append('User', msalContext.accounts[0].username)

            var options = {}
            if (body) {
                options = {
                    method: httpMethod,
                    headers: headers,
                    body: body
                };
            } else {
                options = {
                    method: httpMethod,
                    headers: headers
                };
            }

            let response = await fetch(url, options);

            return {
                status: response.status,
                body: await response.json()
            };

        } else {
            console.log('user not logged in')
        }
    }

    async getInboundData(instance, Namespace, InterfaceName, LastInboundTimeStamp, LastInboundTimeStampCondition, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/inboundmetric?Instance=" + instance + "&namespace=" + Namespace + "&InterfaceName=" + InterfaceName +
            "&LastInboundTimeStamp=" + LastInboundTimeStamp + "&LastInboundTimeStampCondition=" + LastInboundTimeStampCondition + "&FilterByDNMList=true"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getInboundDataById(Id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/inboundmetric/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async editInboundDataById(Id, msalContext, body) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/inboundmetric/" + Id
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getInboundMetricUpdatelogs(Id, msalContext) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/inboundmetric/" + Id + "/auditlogs"
        try {
            const response = await this.invokeWebAPI(url, 'GET', msalContext);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    //HM-938 - Bulk update in case of Inbound In-Activity page.
    async editMiltupleInboundData(msalContext, body) {

        const url = global.ENV_HIE_ADMIN_API_AZURE_URL + "/inboundmetric/multiple"
        try {
            const response = await this.invokeWebAPI(url, 'PUT', msalContext, body);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async getRole(msalContext) {
        if (msalContext.accounts.length > 0) {
            const tokenRequest = {
                scopes: [global.ENV_MSAL_LOGIN_REQUEST_SCOPES],
                account: msalContext.accounts[0],
            };

            // acquire Bearer token to include in GET request
            const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
            const token = tokenResponse.accessToken;

            if (token != '') {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                var result = JSON.parse(jsonPayload);
                if (result.roles === undefined) {
                } else {
                    var roles = result.roles;
                    if (roles.length > 0) {
                        if (roles.includes('Admin.Write'))
                            return 2;
                        if (roles.includes('Admin.Read'))
                            return 1;
                    }
                }
            }
            return 0;
        }
    }
}